import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, Panel, PanelHeader, Group, Header, Cell, Switch, Alert } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

const App = () => {
	const [activePanel, setActivePanel] = useState('default');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(null);

	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
		}
		fetchData();
	}, []);

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	function closePopout() {
		setPopout(null);
	}

	return (
		<AdaptivityProvider>
			<AppRoot>
				<View activePanel={activePanel} popout={popout}>
					<Panel id='default' fetchedUser={fetchedUser} go={go}>
						<PanelHeader>VTosters server-side feautures dashboard</PanelHeader>
						<Group header={<Header mode="secondary">Настройка серверсайд фич</Header>}>
							<Cell after={<Switch onClick={(e)=>{setPopout(<Alert text={`Вечный онлайн в состоянии: ${(e.target.checked)}`} onClose={closePopout} header="Имитация сохранения состояния фичи eternal_online" />)}}/>}>
								Вечный онлайн
							</Cell>
						</Group>
					</Panel>
				</View>
			</AppRoot>
		</AdaptivityProvider>
	);
}

export default App;
